/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormAutomatedActionConfiguration } from '@se/data/forms/types.ts';
import { IGridRow } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useMouseOver } from '@seeeverything/ui.primitives/src/hooks/useMouseOver.ts';
import { clipboard } from '@seeeverything/ui.util/src/clipboard/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo } from 'react';

export interface IAutomationActionDescriptionCellInactiveProps {
  row: IGridRow<FormAutomatedActionConfiguration>;
}

const View: React.FC<IAutomationActionDescriptionCellInactiveProps> = ({
  row,
}) => {
  const [isMouseOver, handleMouseEnter, handleMouseLeave] = useMouseOver();

  return (
    <div
      css={styles.base}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Text italic={true} color={color.format(-0.3)} ellipsis={true} size={14}>
        {row.value.description}
      </Text>
      {isMouseOver && row.value.description && (
        <Icons.contentCopy
          onClick={() => clipboard.copyToClipboard(row.value.description)}
          tooltip={`Copy ${row.value.type === 'Insight' ? 'playbook action' : 'smart action'} to clipboard`}
        />
      )}
    </div>
  );
};

const styles = {
  base: css({
    flex: '1 1 auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 0px',
    minHeight: 37,
    position: 'relative',
  }),
};

export const AutomatedActionsGridDescriptionCellInactive = memo(
  View,
  propsAreEqualFastCompare,
);
