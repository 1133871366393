import { getCommentsList } from './commentsList.ts';
import { getGridFilters } from './gridFilters.ts';
import { addDisplayValueToFilter } from './util.ts';

export const dashboardsQuery = {
  getCommentsList,
  getGridFilters,
  utils: {
    addDisplayValueToFilter,
  },
};
