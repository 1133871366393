import { DashboardV2Entity } from '@se/data/dashboards/query/types.ts';
import { ModuleType, OrderBy } from '@seeeverything/ui.util/src/types.ts';
import { PAGE_SIZE_DASHBOARD_GRID } from '../common/constants.ts';
import {
  DashboardTeam,
  DashboardV2Subject,
  DashboardV2Template,
  IContentPackSelectionListItem,
} from '../data/index.ts';
import {
  COMPONENT_DIGITAL_CONTENT,
  ComponentType,
  DashboardComponent,
  IDashboardGrid,
  IDataPayload,
} from '../types.ts';
import {
  DashboardActionCacheTemplate,
  DashboardActionChartToolButtonClicked,
  DashboardActionChartToolsToggle,
  DashboardActionClearDateFilter,
  DashboardActionClearScrollToPosition,
  DashboardActionClickThroughGridInitialized,
  DashboardActionComponentError,
  DashboardActionComponentViewDetail,
  DashboardActionComponentViewEntity,
  DashboardActionDigitalContentFilterChanged,
  DashboardActionDigitalContentLoad,
  DashboardActionError,
  DashboardActionGridToolButtonClicked,
  DashboardActionGridToolsToggle,
  DashboardActionHeaderLoaded,
  DashboardActionHeaderPathClicked,
  DashboardActionInitialize,
  DashboardActionInitializeChartTools,
  DashboardActionInitializeComponent,
  DashboardActionInitializeGridTools,
  DashboardActionLoad,
  DashboardActionLoadComponentsFromCache,
  DashboardActionLoadDigitalContentPacks,
  DashboardActionLoaded,
  DashboardActionLoadedDigitalContentPacks,
  DashboardActionLoadHeader,
  DashboardActionRememberScrollToPosition,
  DashboardActionSetCustomDateRange,
  DashboardActionSetDateFilter,
  DashboardActionSetFinancialYearStartMonth,
  DashboardActionTemplateLoaded,
  DashboardActionViewClickThroughGrid,
  IClickThroughGridEntity,
  IClickThroughSourceGrid,
} from './types.ts';

export function init(
  module: ModuleType,
  templateId: string,
  entity: DashboardV2Subject,
  entityId: string,
  loadSingleComponentId?: string,
  clickThroughGrid?: IDashboardGrid,
): DashboardActionInitialize {
  return {
    type: 'ui.dashboards/dashboard/INITIALIZE',
    payload: {
      module,
      templateId,
      entity,
      entityId,
      loadSingleComponentId,
      clickThroughGrid,
    },
  };
}

export function templateLoaded(
  template: DashboardV2Template,
  loadedSingleComponentId?: string,
  isFromCache?: boolean,
  clickThroughGrid?: IDashboardGrid,
): DashboardActionTemplateLoaded {
  return {
    type: 'ui.dashboards/dashboard/TEMPLATE_LOADED',
    payload: {
      template,
      loadedSingleComponentId,
      isFromCache,
      clickThroughGrid,
    },
  };
}

export function errorMessage(message: string): DashboardActionError {
  return {
    type: 'ui.dashboards/dashboard/ERROR',
    payload: { error: message },
  };
}

export function digitalContentLoad(
  id: string,
): DashboardActionDigitalContentLoad {
  return {
    type: 'ui.dashboards/dashboard/DIGITAL_CONTENT/LOAD',
    payload: {
      componentType: COMPONENT_DIGITAL_CONTENT,
      id,
    },
  };
}

export function digitalContentFilterChanged(
  id: string,
  selectedContentPack?: IContentPackSelectionListItem,
): DashboardActionDigitalContentFilterChanged {
  return {
    type: 'ui.dashboards/dashboard/DIGITAL_CONTENT/FILTER_CHANGED',
    payload: {
      componentType: COMPONENT_DIGITAL_CONTENT,
      id,
      selectedContentPack,
    },
  };
}

export function loadDashboardComponent(
  componentType: ComponentType,
  id: string,
  orderBy: OrderBy[] = [],
  loadMore = false,
  pageSize = PAGE_SIZE_DASHBOARD_GRID,
): DashboardActionLoad {
  return {
    type: 'ui.dashboards/dashboard/COMPONENT/LOAD',
    payload: {
      componentType,
      id,
      orderBy,
      loadMore,
      pageSize,
    },
  };
}
export function loaded(
  componentType: ComponentType,
  id: string,
  data: IDataPayload | undefined,
  shouldAppendData: boolean,
): DashboardActionLoaded {
  return {
    type: 'ui.dashboards/dashboard/COMPONENT/LOADED',
    payload: {
      componentType,
      id,
      data,
      shouldAppendData,
    },
  };
}

export function initializeComponent(
  component: DashboardComponent,
  cacheWithTemplateId?: string,
): DashboardActionInitializeComponent {
  return {
    type: 'ui.dashboards/dashboard/COMPONENT/INITIALIZE',
    payload: {
      id: component.id,
      componentType: component.componentType,
      component,
      cacheWithTemplateId,
    },
  };
}

export function componentError(
  componentType: ComponentType,
  id: string,
  error: Error,
): DashboardActionComponentError {
  return {
    type: 'ui.dashboards/dashboard/COMPONENT/ERROR',
    payload: {
      componentType,
      id,
      error: error.toString(),
    },
  };
}

export function viewDetail(
  componentType: ComponentType,
  id: string,
): DashboardActionComponentViewDetail {
  return {
    type: 'ui.dashboards/dashboard/COMPONENT/VIEW_DETAIL',
    payload: {
      id,
      componentType,
    },
  };
}

export function viewEntity(
  componentId: string,
  entityId: string,
  entityType: DashboardV2Entity,
  entityLabel?: string,
): DashboardActionComponentViewEntity {
  return {
    type: 'ui.dashboards/dashboard/COMPONENT/VIEW_ENTITY',
    payload: {
      entity: {
        id: entityId,
        type: entityType,
        label: entityLabel,
      },
      componentId,
    },
  };
}

export function toggleGridTool(
  toolId: string,
  buttonId: string,
  module: ModuleType,
): DashboardActionGridToolsToggle {
  return {
    type: 'ui.dashboards/dashboard/GRID_TOOLS/TOGGLE',
    payload: { toolId, buttonId, module },
  };
}

export function toggleChartTool(
  toolId: string,
  buttonId: string,
  module: ModuleType,
): DashboardActionChartToolsToggle {
  return {
    type: 'ui.dashboards/dashboard/CHART_TOOLS/TOGGLE',
    payload: { toolId, buttonId, module },
  };
}

export function gridToolButtonClicked(
  id: string,
  parentId: string,
): DashboardActionGridToolButtonClicked {
  return {
    type: 'ui.dashboards/dashboard/GRID_TOOLS/BUTTON_CLICKED',
    payload: { id, parentId },
  };
}

export function chartToolButtonClicked(
  id: string,
  parentId: string,
): DashboardActionChartToolButtonClicked {
  return {
    type: 'ui.dashboards/dashboard/CHART_TOOLS/BUTTON_CLICKED',
    payload: { id, parentId },
  };
}

/**
 * Handles setting default selection options for the chart tool buttons.
 */
export function initializeChartTools(
  module: ModuleType,
): DashboardActionInitializeChartTools {
  return {
    type: 'ui.dashboards/dashboard/CHART_TOOLS/INITIALIZE',
    payload: { module },
  };
}

/**
 * Handles setting default selection options for the grid tool buttons.
 */
export function initializeGridTools(
  module: ModuleType,
): DashboardActionInitializeGridTools {
  return {
    type: 'ui.dashboards/dashboard/GRID_TOOLS/INITIALIZE',
    payload: { module },
  };
}

export function loadHeader(): DashboardActionLoadHeader {
  return {
    type: 'ui.dashboards/dashboard/HEADER/LOAD',
    payload: {},
  };
}

export function headerLoaded(data: IDataPayload): DashboardActionHeaderLoaded {
  return {
    type: 'ui.dashboards/dashboard/HEADER/LOADED',
    payload: { data },
  };
}

export function headerPathClicked(
  value: string,
  label: string,
): DashboardActionHeaderPathClicked {
  return {
    type: 'ui.dashboards/dashboard/HEADER/PATH_CLICKED',
    payload: {
      value,
      label,
    },
  };
}

export function setDateFilter(
  itemId: string,
  reloadHeader = true,
): DashboardActionSetDateFilter {
  return {
    type: 'ui.dashboards/dashboard/HEADER/SET_DATE_FILTER',
    payload: { itemId, reloadHeader },
  };
}

export function clearDateFilter(): DashboardActionClearDateFilter {
  return {
    type: 'ui.dashboards/dashboard/HEADER/CLEAR_DATE_FILTER',
    payload: {},
  };
}

export function setCustomDateRange(
  startDateISO: string,
  endDateISO: string,
  reloadHeader = true,
): DashboardActionSetCustomDateRange {
  return {
    type: 'ui.dashboards/dashboard/HEADER/SET_CUSTOM_DATE_RANGE',
    payload: { startDateISO, endDateISO, reloadHeader },
  };
}

export function setFinancialYearStartMonth(
  financialYearStartMonth?: number,
): DashboardActionSetFinancialYearStartMonth {
  return {
    type: 'ui.dashboards/dashboard/HEADER/SET_FINANCIAL_YEAR_START_MONTH',
    payload: { financialYearStartMonth },
  };
}

export function viewClickThroughGrid(
  clickThroughGridId: string,
  forEntity: IClickThroughGridEntity,
  sourceGrid: IClickThroughSourceGrid,
  clickThroughDefinitionGridId?: string,
): DashboardActionViewClickThroughGrid {
  return {
    type: 'ui.dashboards/dashboard/GRID/VIEW_CLICK_THROUGH_GRID',
    payload: {
      clickThroughGridId,
      forEntity,
      sourceGrid,
      clickThroughDefinitionGridId,
    },
  };
}

export function clickThroughGridInitialized(
  clickThroughGrid: IDashboardGrid,
): DashboardActionClickThroughGridInitialized {
  return {
    type: 'ui.dashboards/dashboard/GRID/CLICK_THROUGH_GRID_INITIALIZED',
    payload: {
      clickThroughGrid,
    },
  };
}

export function rememberScrollToPosition(
  componentId: string,
): DashboardActionRememberScrollToPosition {
  return {
    type: 'ui.dashboards/dashboard/SCROLL/REMEMBER_POSITION',
    payload: { componentId },
  };
}

export function clearScrollToPosition(): DashboardActionClearScrollToPosition {
  return {
    type: 'ui.dashboards/dashboard/SCROLL/CLEAR_REMEMBERED_POSITION',
    payload: {},
  };
}

export const loadComponentsFromCache = (
  templateId: string,
  module: ModuleType,
  entityId: string,
  entity?: DashboardTeam,
): DashboardActionLoadComponentsFromCache => ({
  type: 'ui.dashboards/dashboard/CACHE/LOAD_COMPONENTS',
  payload: { templateId, entityId, entity, module },
});

export const cacheTemplate = (
  template: DashboardV2Template,
): DashboardActionCacheTemplate => ({
  type: 'ui.dashboards/dashboard/CACHE/CACHE_TEMPLATE',
  payload: { template },
});

export function loadDigitalContentPacksPage(
  startDate?: string,
  endDate?: string,
): DashboardActionLoadDigitalContentPacks {
  return {
    type: 'ui.dashboards/dashboard/DIGITAL_CONTENT/LOAD_CONTENT_PACKS_PAGE',
    payload: { startDate, endDate },
  };
}

export function loadedDigitalContentPacksPage(
  items: IContentPackSelectionListItem[],
  hasNextPage: boolean,
): DashboardActionLoadedDigitalContentPacks {
  return {
    type: 'ui.dashboards/dashboard/DIGITAL_CONTENT/LOADED_CONTENT_PACKS_PAGE',
    payload: { items, hasNextPage },
  };
}
