/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryCommentsList } from '@se/data/dashboards/hooks/useQueryCommentsList.ts';
import { ContentPlaceholder } from '@seeeverything/ui.primitives/src/components/ContentPlaceholder/ContentPlaceholder.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import { useSegmentAnalytics } from '@seeeverything/ui.util/src/analytics/SegmentProvider.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo, useEffect, useMemo, useState } from 'react';
import {
  DASHBOARD_INTERSECTION_OPTIONS,
  PAGE_SIZE_DASHBOARD_GRID,
} from '../common/constants.ts';
import { CommentsList } from '../components/CommentsList/CommentsList.tsx';
import { toDateFilter, toTimespanVariable } from '../data/util.ts';
import { useDashboardsSelector } from '../redux/store.ts';

export interface IFactoryCommentsListContainerProps {
  id: string;
  parentId?: string;
}

const View: React.FC<IFactoryCommentsListContainerProps> = ({
  id,
  parentId,
}) => {
  const { track } = useSegmentAnalytics();

  const [forwardedRef, inViewport] = useTrackInViewport({
    dataId: id,
    parentDataId: parentId,
    observerOptions: DASHBOARD_INTERSECTION_OPTIONS,
  });

  const [isInit, setIsInit] = useState(inViewport);

  useEffect(() => {
    if (isInit) return;
    if (!inViewport) return;
    setIsInit(true);
  }, [inViewport, isInit]);

  const template = useDashboardsSelector(
    (state) => state.dashboardsV2.template,
  );

  const commentsList = useDashboardsSelector(
    (state) => state.dashboardsV2.COMMENTS_LIST[id],
  );

  const dateFilter = useDashboardsSelector((state) => toDateFilter(state));
  const timespan = useMemo(() => toTimespanVariable(dateFilter), [dateFilter]);

  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  useEffect(() => {
    if (commentsList && template && inViewport && !isQueryEnabled) {
      track('dashboard_section_load', { sectionLabel: id });
      setIsQueryEnabled(true);
    }
  }, [commentsList, id, inViewport, isQueryEnabled, template, track]);

  const {
    comments,
    commentsHasNextPage,
    isLoadingComments,
    isFetchingComments,
    isFetchingCommentsNextPage,
    fetchNextCommentsPage,
    isErrorComments,
  } = useQueryCommentsList({
    dataSetId: commentsList?.dataState.dataSetId,
    type: 'DASHBOARD_COMPONENT',
    entityId: template?.entityId,
    entityType: template?.kind,
    commentListId: id,
    dataKeys: commentsList?.dataKeys,
    isQueryEnabled,
    orderBy: commentsList?.orderBy,
    pageSize: PAGE_SIZE_DASHBOARD_GRID,
    templateId: template?.id,
    timespan,
  });

  const elError = isErrorComments && (
    <Text color={color.format(-0.65)}>
      {'There was a problem. Please try again.'}
    </Text>
  );

  const elCommentsList = isInit && !isErrorComments && (
    <CommentsList
      dashboardComponentId={id}
      commentsList={commentsList}
      comments={comments}
      isLoading={isLoadingComments}
      isFetching={isFetchingComments}
      isFetchingNextPage={isFetchingCommentsNextPage}
      hasMorePages={commentsHasNextPage}
      fetchNextPage={fetchNextCommentsPage}
      type={'DASHBOARD_COMPONENT'}
      entityType={template.kind}
    />
  );

  const elInitPlaceholder = !isInit && !isErrorComments && (
    <div css={styles.loadingPlaceholder}>
      <ContentPlaceholder kind={'LIST'} emptyText={''} hintText={''} />
    </div>
  );

  return (
    <div
      data-id={id}
      parent-data-id={parentId}
      ref={forwardedRef}
      css={styles.base}
    >
      {elError}
      {elCommentsList}
      {elInitPlaceholder}
    </div>
  );
};

const styles = {
  base: css({
    margin: '30px 30px 30px 35px',
  }),
  loadingPlaceholder: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 300,
    width: '100%',
    textAlign: 'center',
    paddingTop: 50,
  }),
};

export const FactoryCommentsListContainer = memo(
  View,
  propsAreEqualFastCompare,
);
