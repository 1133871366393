import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  OrderBy,
  PageInfoRequest,
  PageInfoResponse,
} from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import {
  DashboardComment,
  DashboardCommentDataKeys,
  DashboardTimespanVariable,
  DashboardV2Entity,
} from './types.ts';
import { formatDateString } from './util.ts';

type GetCommentListArgs = {
  dataSetId: string;
  entityId: string;
  entityType: 'person' | 'team';
  gridId: string;
  dataKeys: DashboardCommentDataKeys;
  orderBy: OrderBy[];
  pagination: PageInfoRequest;
  templateId: string;
  timespan: DashboardTimespanVariable;
};

type ResponseDataRow = {
  key: string;
  tags: string[];
  entity: {
    id: string;
    type: DashboardV2Entity;
    label: string;
  };
  data: Array<{ key: string; value?: string }>;
};

type ResponseColumn = {
  key: string;
  maskIfSensitive: boolean;
};

type ResponseComment = {
  rows: {
    pageInfo: PageInfoResponse;
    nodes: ResponseDataRow[];
  };
  columns?: ResponseColumn[];
};

export type DashboardCommentListDto = {
  pageInfo: PageInfoResponse;
  comments: DashboardComment[];
  entityType: 'person' | 'team';
  entityId: string;
  templateId: string;
};

export const getCommentsList = async (
  client: IGraphQLClient,
  args: GetCommentListArgs,
): Promise<QueryResult<DashboardCommentListDto>> => {
  try {
    let pageNumber = args.pagination.pageNumber;
    while (true) {
      const commentList =
        args.entityType === 'person'
          ? await personResponseComments(client, {
              ...args,
              pagination: { ...args.pagination, pageNumber },
            })
          : await teamResponseComments(client, {
              ...args,
              pagination: { ...args.pagination, pageNumber },
            });

      if (!commentList) {
        log.error(
          `Failed to retrieve dashboard comments list for type '${args.entityType}' with id '${args.entityId}.`,
        );
        return { isSuccess: false, errorReason: 'NOT_FOUND' };
      }

      // If all nodes have empty descriptions (comment answer)
      // and there is a next page then disregard this page and
      // get the next page
      if (
        commentList.rows.pageInfo.hasNextPage &&
        args.dataKeys.description?.length &&
        commentList.rows.nodes.every(
          (node) =>
            !node.data.some(
              (data) =>
                args.dataKeys.description.some(
                  (dataKey) => dataKey.description === data.key,
                ) && data.value?.length,
            ),
        )
      ) {
        pageNumber++;
        continue;
      }

      return {
        isSuccess: true,
        data: {
          pageInfo: commentList.rows.pageInfo,
          // Filter out any comments node that do not have at least
          // one non-empty description (comment answer)
          comments: commentList.rows.nodes
            .filter(
              (node) =>
                !args.dataKeys.description?.length ||
                node.data
                  .filter((data) =>
                    args.dataKeys.description.some(
                      (dataKey) => dataKey.description === data.key,
                    ),
                  )
                  .some((data) => data.value?.length),
            )
            .map(
              (node): DashboardComment => ({
                id: node.key,
                assignedTo: node.data.find(
                  (d) => d.key === args.dataKeys.assignedTo,
                )?.value,
                subject: node.data.find((d) => d.key === args.dataKeys.subject)
                  .value,
                description: {
                  items: args.dataKeys.description?.map((descriptionItem) => ({
                    title: descriptionItem.label,
                    description: node.data.find(
                      (d) => d.key === descriptionItem.description,
                    )?.value,
                  })),
                },
                occurredAt:
                  formatDateString(
                    node.data.find((d) => d.key === args.dataKeys.occurredAt)
                      .value,
                    {
                      format: 'D MMM YYYY',
                      defaultValue: '-',
                    },
                  ) || '-',
                form:
                  node.entity?.type === 'Form'
                    ? { id: node.entity.id, name: node.entity.label }
                    : undefined,
              }),
            ),
          entityId: args.entityId,
          entityType: args.entityType,
          templateId: args.templateId,
        },
      };
    }
  } catch (error) {
    log.error(
      `Something went wrong trying to query dashboard comments list - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};

const personResponseComments = async (
  client: IGraphQLClient,
  args: GetCommentListArgs,
) => {
  const response = await client.query<{
    dashboards: { person: { grid: ResponseComment } };
  }>({
    fetchPolicy: 'network-only',
    query: gql`
      query PersonCommentList(
        $entityId: ID!
        $dataSetId: ID!
        $gridId: ID!
        $pageSize: Int!
        $pageNumber: Int!
        $templateId: ID!
        $orderBy: [OrderByInput!]
        $timespan: DashboardTimespanFilterInput
      ) {
        dashboards {
          person(id: $entityId) {
            grid(
              dataSetId: $dataSetId
              id: $gridId
              templateId: $templateId
              pagination: { size: $pageSize, pageNumber: $pageNumber }
              filterStatements: []
              orderBy: $orderBy
              timespan: $timespan
            ) {
              rows {
                pageInfo {
                  currentPage
                  hasNextPage
                  pageSize
                  totalCount
                }
                nodes {
                  key
                  tags
                  entity {
                    id
                    type
                    label
                  }
                  data {
                    key
                    value
                  }
                }
              }
              columns {
                key
                maskIfSensitive
              }
            }
          }
        }
      }
    `,
    variables: {
      entityId: args.entityId,
      gridId: args.gridId,
      dataSetId: args.dataSetId,
      pageSize: args.pagination.pageSize,
      pageNumber: args.pagination.pageNumber,
      templateId: args.templateId,
      orderBy: args.orderBy,
      timespan: args.timespan,
    },
  });

  if (!response.data.dashboards.person.grid) return;

  const rows = response.data.dashboards.person.grid.rows;
  const maskedColumns = response.data.dashboards.person.grid.columns
    ?.filter((column) => column.maskIfSensitive)
    .map((column) => column.key);

  return {
    ...response.data.dashboards.person.grid,
    rows: {
      ...rows,
      nodes: rows.nodes.map((node: ResponseDataRow) => ({
        ...node,
        data: node.data.map((data) => ({
          ...data,
          isMasked:
            node.tags?.includes('IsSensitive') &&
            maskedColumns?.includes(data.key),
        })),
      })),
    },
  };
};

const teamResponseComments = async (
  client: IGraphQLClient,
  args: GetCommentListArgs,
) => {
  const response = await client.query<{
    dashboards: { team: { grid: ResponseComment } };
  }>({
    fetchPolicy: 'network-only',
    query: gql`
      query TeamCommentList(
        $entityId: ID!
        $dataSetId: ID!
        $gridId: ID!
        $pageSize: Int!
        $pageNumber: Int!
        $templateId: ID!
        $orderBy: [OrderByInput!]
        $timespan: DashboardTimespanFilterInput
      ) {
        dashboards {
          team(id: $entityId) {
            grid(
              dataSetId: $dataSetId
              id: $gridId
              templateId: $templateId
              pagination: { size: $pageSize, pageNumber: $pageNumber }
              filterStatements: []
              orderBy: $orderBy
              timespan: $timespan
            ) {
              rows {
                pageInfo {
                  currentPage
                  hasNextPage
                  pageSize
                  totalCount
                }
                nodes {
                  key
                  tags
                  entity {
                    id
                    type
                    label
                  }
                  data {
                    key
                    value
                  }
                }
              }
              columns {
                key
                maskIfSensitive
              }
            }
          }
        }
      }
    `,
    variables: {
      entityId: args.entityId,
      gridId: args.gridId,
      dataSetId: args.dataSetId,
      pageSize: args.pagination.pageSize,
      pageNumber: args.pagination.pageNumber,
      templateId: args.templateId,
      orderBy: args.orderBy,
      timespan: args.timespan,
    },
  });

  if (!response.data.dashboards.team.grid) return;

  const rows = response.data.dashboards.team.grid.rows;
  const maskedColumns = response.data.dashboards.team.grid.columns
    ?.filter((column) => column.maskIfSensitive)
    .map((column) => column.key);

  return {
    ...response.data.dashboards.team.grid,
    rows: {
      ...rows,
      nodes: rows.nodes.map((node: ResponseDataRow) => ({
        ...node,
        data: node.data.map((data) => ({
          ...data,
          isMasked:
            node.tags?.includes('IsSensitive') &&
            maskedColumns?.includes(data.key),
        })),
      })),
    },
  };
};
