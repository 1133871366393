import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import { IDashboardCommentsList } from '../types.ts';

/**
 * Attempts to parse values to construct a comments-list.
 */
export function parser(
  item: any,
  index: number,
): IDashboardCommentsList | undefined {
  const commentsList = item['comments-list'];
  if (!commentsList) {
    return;
  }

  const defaultSortOrder = commentsList['default-sort-order'];
  const orderBy: OrderBy[] = defaultSortOrder
    ? [
        {
          fieldName: defaultSortOrder.id,
          direction: defaultSortOrder.direction,
        },
      ]
    : [];

  const dataState = {
    dataSetId: commentsList.id,
    isLoaded: false,
    isLoading: false,
    forceReload: false,
  };

  return {
    componentType: 'COMMENTS_LIST',
    id: commentsList.id,
    index,
    title: commentsList.title || 'Recent Comments',
    dataState,
    dataKeys: parseDataKeys(commentsList['data-keys']),
    orderBy,
  };
}

const parseDataKeys = (item?: any) => ({
  assignedTo: item?.assignedTo ? item.assignedTo : 'assignedTo',
  subject: item?.subject ? item.subject : 'subject',
  description: item.description ? item.description : [],
  occurredAt: item?.occurredAt ? item.occurredAt : 'occurredAt',
});
