/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { useQueryFormTemplatesByCategory } from '@se/data/forms/hooks/useQueryFormTemplatesByCategory.ts';
import { FormTemplate } from '@se/data/forms/types.ts';
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback, useMemo } from 'react';
import { automatedActionConfigurationSlice } from '../../../redux/automatedActionConfiguration/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { AutomatedActionsHeaderDetails } from './AutomatedActionsHeaderDetails.tsx';

export interface IAutomatedActionsHeaderProps {
  dropdownHighlight: boolean;
}

export const AutomatedActionsHeader: React.FC<IAutomatedActionsHeaderProps> = ({
  dropdownHighlight,
}) => {
  const dispatch = useFormsDispatch();

  const module = useFormsSelector((state) => state.tenantState.tenant.module);

  const { templateSelections, templatesLoading } =
    useQueryFormTemplatesByCategory({
      hasIssuesOnly: module === 'compliance',
      hasInsightsOnly: module === 'coaching',
    });

  const formLabel = useTenantLocale((l) => l.label.form);

  const selectedTemplate = useFormsSelector(
    (state) => state.automatedActionConfiguration.selectedTemplate,
  );

  const selectedTemplateSelection = useMemo(
    () =>
      selectedTemplate && {
        id: selectedTemplate.id,
        content: { text: selectedTemplate.name },
      },
    [selectedTemplate],
  );

  const handleSelectTemplate = useCallback(
    (to: ISelectionListItem<IListItemLabel, FormTemplate>) => {
      if (!to?.value) return;
      dispatch(automatedActionConfigurationSlice.selectTemplate(to.value));
    },
    [dispatch],
  );

  return (
    <div css={styles.base}>
      <IconWrapper
        icon={'document'}
        style={
          dropdownHighlight
            ? [styles.dropdown, styles.dropdownHighlight]
            : styles.dropdown
        }
      >
        <TextFieldDropdown
          id={'template'}
          selections={templateSelections}
          isLoading={templatesLoading}
          label={`Select ${formLabel} Type`}
          value={selectedTemplateSelection}
          onChange={handleSelectTemplate}
        />
      </IconWrapper>
      <AutomatedActionsHeaderDetails />
    </div>
  );
};

const styles = {
  base: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 0 auto',
  }),
  dropdown: css({
    maxWidth: '50%',
    padding: '5px 10px 15px 10px',
    margin: '-5px -10px -15px -10px',
    border: `solid 1px ${color.format(0)}`,
  }),
  dropdownHighlight: css({
    animation: `${keyframes({
      '0%': { backgroundColor: color.create(COLORS.BLUE).alpha(0.18).hex() },
      '50%': { backgroundColor: color.create(COLORS.BLUE).alpha(0.1).hex() },
      '100%': { backgroundColor: color.create(COLORS.BLUE).alpha(0.18).hex() },
    })} 2.33s ease-in-out infinite`,
    border: `solid 1px ${color.format(-0.1)}`,
    borderRadius: 5,
  }),
};
