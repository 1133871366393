/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export type CommentsDescriptionListItemProps = {
  title: string;
  description: string;
};

export const CommentsDescriptionListItem: React.FC<
  CommentsDescriptionListItemProps
> = ({ title, description }) => (
  <div>
    <Text
      color={color.format(-0.6)}
      weight={900}
      size={14}
      style={styles.commentListText}
    >
      {title}
      {':'}
    </Text>
    <Text color={color.format(-0.65)} size={14} style={styles.commentListText}>
      {description}
    </Text>
  </div>
);

const styles = {
  commentListText: css({
    marginBottom: 4,
    marginRight: 4,
  }),
};
