/** @jsxImportSource @emotion/react */
import { DashboardCommentType } from '@se/data/dashboards/query/types.ts';
import { CommentsDescriptionListItem } from './CommentsDescriptionListItem.tsx';

export type CommentsDescriptionProps = {
  description: DashboardCommentType;
};

export const CommentsDescription: React.FC<CommentsDescriptionProps> = ({
  description,
}) => {
  const elItems = description.items
    .filter((item) => Boolean(item.description))
    .map((item, index) => (
      <div key={`${item.title}-${index}`}>
        <CommentsDescriptionListItem
          title={item.title}
          description={item.description}
        />
      </div>
    ));

  return <div>{elItems}</div>;
};
