/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { urlDeepLinkUtil } from '@seeeverything/ui.util/src/urlDeepLink/index.ts';
import { useCallback } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';

export const ActionInsightSessionDetailsContainer: React.FC = () => {
  const localeLabels = useTenantLocale((locale) => ({
    formLabel: locale.label.form,
    formSubject: locale.label.formSubject,
  }));

  const insightDetails = useFormsSelector(
    (state) => state.formActionV2.insight,
  );

  const tenant = useFormsSelector((state) => state.tenantState.tenant.id);

  const openInstanceUrl = useCallback(() => {
    if (!insightDetails) return;
    if (!insightDetails.instance) return;
    if (!tenant) return;

    const url = urlDeepLinkUtil.generateUrlPath({
      tenant,
      module: 'coaching',
      chips: [
        {
          type: 'forms',
          value: insightDetails.instance.id,
          label: insightDetails.instance.template.name,
        },
      ],
    });
    window.open(url, '_blank');
  }, [insightDetails, tenant]);

  const elInstanceDetails = insightDetails?.instance && (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.dashboard fill={color.format(-0.5)} />
        <div css={styles.headerText}>
          <Text>{`${localeLabels.formLabel}: `}</Text>
          <Button onClick={openInstanceUrl}>
            <div css={styles.linkButton}>
              <Text color={COLORS.BLUE} cursor={'pointer'}>
                {insightDetails.instance.template.name}
              </Text>
              <Icons.launch />
            </div>
          </Button>
        </div>
      </div>
      <div css={styles.sessionFields}>
        {insightDetails.instance.subject && (
          <div css={styles.sessionField}>
            {insightDetails.instance.subject.type === 'Person' ? (
              <Icons.face
                fill={color.format(-0.5)}
                tooltip={`${localeLabels.formLabel} ${localeLabels.formSubject}`}
              />
            ) : (
              <Icons.group
                fill={color.format(-0.5)}
                tooltip={`${localeLabels.formLabel} ${localeLabels.formSubject}`}
              />
            )}
            <Text>{insightDetails.instance.subject.name}</Text>
          </div>
        )}
        <div css={styles.sessionField}>
          <Icons.info
            fill={color.format(-0.5)}
            tooltip={`${localeLabels.formLabel} Status`}
          />
          <Text>{insightDetails.instance.statusDisplayValue}</Text>
        </div>
        {insightDetails.instance.datesDisplayValue && (
          <div css={styles.sessionField}>
            <Icons.notifications
              fill={color.format(-0.5)}
              tooltip={`${localeLabels.formLabel} Dates`}
            />
            <Text>{insightDetails.instance.datesDisplayValue}</Text>
          </div>
        )}
      </div>
    </div>
  );

  const elInsightTitle = insightDetails?.label && (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.localLibrary fill={COLORS.PURPLE} tooltip={'Learning Area'} />
        <div css={styles.headerText}>
          <Text color={COLORS.PURPLE}>{'Learning Area: '}</Text>
          <Text>{insightDetails.label}</Text>
        </div>
      </div>
    </div>
  );

  const sectionName = insightDetails.formSectionName
    ? `*${str.titleCase(
        str.removeMarkdownAndSpecialCharacters(insightDetails.formSectionName),
      )}*`
    : '';

  const questionText = insightDetails.formQuestionText
    ? str.removeMarkdownAndSpecialCharacters(insightDetails.formQuestionText)
    : '';

  const questionTextLabel = str.conditionalSuffix(
    questionText,
    ['?', ':'],
    ':',
  );

  const answerText = insightDetails.answer
    ? `**${insightDetails.answer.displayValue ?? insightDetails.answer.value}**`
    : '';

  const formQuestionText = [
    [sectionName, questionTextLabel].filter(Boolean).join(' -- '),
    answerText,
  ]
    .filter(Boolean)
    .join(' ');

  const elFormQuestion = (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.helpOutline
          fill={color.format(-0.5)}
          tooltip={`${localeLabels.formLabel} section, question, and response details`}
        />
        <div css={styles.headerText}>
          <Markdown text={formQuestionText} />
        </div>
      </div>
      {insightDetails.classifications?.map((classification, index) => (
        <Markdown
          key={`${insightDetails.id}-${index}`}
          style={styles.classificationText}
          text={`- ${classification}`}
        />
      ))}
    </div>
  );

  const elNotes = insightDetails.notes && (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.questionAnswerIcon
          fill={color.format(-0.5)}
          tooltip={'Notes'}
          cursor={'pointer'}
        />
        <div css={styles.headerText}>
          <Markdown text={`Notes: *${insightDetails.notes}*`} />
        </div>
      </div>
    </div>
  );

  return (
    <div css={styles.base}>
      {elInsightTitle}
      {elInstanceDetails}
      {elFormQuestion}
      {elNotes}
    </div>
  );
};

const styles = {
  base: css({
    ...CommonStyles.BoxShadowGroup,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    padding: 12,
  }),
  contentGroup: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    ':hover': {
      backgroundColor: color.format(-0.06),
      padding: 10,
      margin: -10,
    },
  }),
  contentRow: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    alignItems: 'center',
  }),
  headerText: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  }),
  sessionFields: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '5%',
    paddingLeft: 40,
    alignItems: 'center',
  }),
  sessionField: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  }),
  linkButton: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  classificationText: css({
    paddingLeft: 25,
  }),
};
