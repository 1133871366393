/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryCommentsList } from '@se/data/dashboards/hooks/useQueryCommentsList.ts';
import { PAGE_SIZE_FULL_GRID } from '@seeeverything/ui.dashboards/src/common/constants.ts';
import { CommentsList } from '@seeeverything/ui.dashboards/src/components/CommentsList/CommentsList.tsx';
import {
  toDateFilter,
  toTimespanVariable,
} from '@seeeverything/ui.dashboards/src/data/util.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { useMemo } from 'react';
import { useShellSelector } from '../../redux/store.ts';
import { SheetError } from '../SheetError/SheetError.tsx';
import { SheetLayout } from '../SheetLayout/SheetLayout.tsx';

export interface ISheetCommentsListContainerProps {
  dashboardComponentId: string;
}

export const SheetCommentsListContainer: React.FC<
  ISheetCommentsListContainerProps
> = ({ dashboardComponentId }) => {
  const commentsList = useShellSelector(
    (state) => state.dashboardsV2.COMMENTS_LIST[dashboardComponentId],
  );

  const template = useShellSelector((state) => state.dashboardsV2.template);

  const dashboardError = useShellSelector((state) => state.dashboardsV2.error);

  const dateFilter = useShellSelector((state) => toDateFilter(state));
  const timespan = useMemo(() => toTimespanVariable(dateFilter), [dateFilter]);

  const isQueryEnabled = useMemo(
    () => Boolean(commentsList?.dataState.dataSetId && template),
    [commentsList?.dataState.dataSetId, template],
  );

  const {
    comments,
    commentsHasNextPage,
    isLoadingComments,
    isFetchingComments,
    isFetchingCommentsNextPage,
    fetchNextCommentsPage,
    isErrorComments,
  } = useQueryCommentsList({
    dataSetId: commentsList?.dataState.dataSetId,
    type: 'FULL_SHEET',
    entityId: template?.entityId,
    entityType: template?.kind,
    commentListId: dashboardComponentId,
    dataKeys: commentsList?.dataKeys,
    isQueryEnabled,
    orderBy: commentsList?.orderBy,
    pageSize: PAGE_SIZE_FULL_GRID,
    templateId: template?.id,
    timespan,
  });

  const isError = useMemo(() => {
    if (!commentsList) return false;
    if (dashboardError) return true;
    if (isErrorComments) return true;
    return false;
  }, [commentsList, dashboardError, isErrorComments]);

  const chips = useShellSelector((state) => state.query.query.chips);
  const title = useMemo(() => {
    if (!chips?.length) return;
    return chips[chips.length - 1]?.label;
  }, [chips]);

  if (isError)
    return (
      <SheetError
        isSpinning={
          isLoadingComments || isFetchingComments || isFetchingCommentsNextPage
        }
        message={
          'There was a problem retrieving your data. You may not have permission to view this.'
        }
        detail={
          'Please contact your administrator if you think you are seeing this in error or would like to change your permissions.'
        }
      />
    );

  if (!commentsList || !template) return <Spinner center={true} />;

  return (
    <SheetLayout title={title}>
      <div css={styles.base}>
        <CommentsList
          dashboardComponentId={dashboardComponentId}
          commentsList={commentsList}
          comments={comments}
          isLoading={isLoadingComments}
          isFetching={isFetchingComments}
          isFetchingNextPage={isFetchingCommentsNextPage}
          hasMorePages={commentsHasNextPage}
          fetchNextPage={fetchNextCommentsPage}
          type={'FULL_SHEET'}
          entityType={template.kind}
        />
      </div>
    </SheetLayout>
  );
};

const styles = {
  base: css({
    margin: '5px 10px',
  }),
};
