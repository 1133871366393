import { DashboardV2Entity } from '@se/data/dashboards/query/types.ts';
import { formatDateString } from '@se/data/dashboards/query/util.ts';
import {
  DataGridColumnType,
  IDataGridColumn,
} from '@seeeverything/ui.primitives/src/components/DataGrid/types.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { isNumber } from '@seeeverything/ui.util/src/value/value.ts';
import { is, isEmpty, isNil } from 'ramda';
import {
  GridDataPoint,
  GridDataRow,
  IDashboardExtendedGridColumn,
  IGridCellDatapoint,
} from '../../types.ts';
import {
  formatEnumString,
  formatPercentageString,
  matchTargetValue,
} from '../../util/util.ts';

export const toRowData = (
  columns: IDashboardExtendedGridColumn<string>[],
  row: GridDataRow,
  locale: TenantLocale,
  isFooter = false,
) => {
  if (
    isFooter &&
    (row.data ?? []).every(
      (dataPoint) => isNil(dataPoint?.value) || isEmpty(dataPoint?.value),
    )
  )
    return undefined;

  const enrichedData = columns.map((column) =>
    toColumnData(column, row, locale),
  );

  return {
    ...row,
    data: enrichedData.map((dataPoint) => dataPoint.displayValue),
    enrichedData,
    isSelectable: !isFooter,
  };
};

const toColumnData = (
  column: IDataGridColumn,
  row: GridDataRow,
  locale: TenantLocale,
) => {
  const data = row.data.find(({ key }) => key === column.id);
  if (!data) return { value: 'N/A', displayValue: 'N/A' };

  const dataComparisonPeriod = row.data.find(
    ({ key }) => key === `${column.id}.comparison-period`,
  );
  return addTrendAndDisplayValues(
    data,
    dataComparisonPeriod,
    column,
    row,
    locale,
  );
};

export const applyContentMapping = (
  dataPoint: IGridCellDatapoint,
  column: IDashboardExtendedGridColumn,
): IGridCellDatapoint => {
  if (!column?.contentMap) return dataPoint;

  const contentMap = matchTargetValue(column.contentMap, dataPoint);
  const contentMapLabel = contentMap?.label;
  let newLabel = dataPoint.displayValue;

  if (contentMapLabel) {
    newLabel = contentMapLabel !== '-' ? contentMapLabel : '';
  }

  return { ...dataPoint, displayValue: newLabel };
};

export const addTrendAndDisplayValues = (
  data: GridDataPoint,
  dataComparisonPeriod: GridDataPoint,
  column: Partial<IDataGridColumn> = {},
  row: GridDataRow,
  locale: TenantLocale,
): IGridCellDatapoint => {
  const { type, format, precision } = column;
  const { value, isMasked } = data;
  const maskedValue = maskValue(
    value,
    type,
    isMasked,
    row.entity?.type,
    locale,
  );
  const maskedPreviousValue = maskValue(
    dataComparisonPeriod?.value,
    type,
    isMasked,
    row.entity?.type,
    locale,
  );
  const valueNumber = Number(maskedValue);

  const trend =
    isNumber(valueNumber) && isNumber(maskedPreviousValue)
      ? valueNumber - maskedPreviousValue
      : undefined;

  const displayTrend =
    trend !== undefined
      ? formatValue(
          trend < 0 ? trend * -1 : trend,
          type,
          format,
          precision || 2,
        )
      : '-';

  return {
    displayValue: formatValue(maskedValue, type, format, precision, row.tags),
    value: maskedValue,
    displayPreviousValue: maskedPreviousValue
      ? formatValue(maskedPreviousValue, type, format, precision, row.tags)
      : undefined,
    previousValue: maskedPreviousValue,
    displayTrend,
    trend,
    rowTags: row.tags,
    isMasked,
  };
};

const maskValue = (
  value: string,
  type: DataGridColumnType = 'STRING',
  isMasked: boolean,
  entityType: DashboardV2Entity,
  locale: TenantLocale,
) => {
  if (type !== 'STRING') return value;
  if (!isMasked) return value;

  const entityLabel =
    entityType === 'Goal'
      ? 'Goal'
      : entityType === 'Action'
        ? (locale.label.action ?? 'Action')
        : '';

  return str.humanize(
    `${entityLabel} details hidden due to permission restrictions.`.trimStart(),
  );
};

const formatValue = (
  value: any,
  type: DataGridColumnType = 'STRING',
  format?: string,
  precision?: number,
  rowTags?: string[],
): string => {
  switch (type) {
    case 'STRING':
    case 'BOOLEAN':
    case 'COMPONENT':
      return value ? value.toString() : '';

    case 'NUMBER':
      return formatNumberValue(value, format, precision, rowTags);

    case 'ENUM':
      return formatEnumString(value);

    case 'DATE':
      return formatDateString(value, { format }) || '';

    default:
      return value?.toString() ?? '';
  }
};

const formatNumberValue = (
  value: any,
  format?: string,
  precision?: number,
  rowTags?: string[],
): string => {
  switch (format) {
    case 'PERCENTAGE':
      return value % 1 === 0
        ? formatPercentageString(value)
        : formatPercentageString(value, precision);

    case 'OVERDUE': {
      const numberValue = Number(value);

      if (isNil(value) || value === '' || numberValue > 0) return '';
      if (numberValue === 0) return 'Due Today';
      if (numberValue === -1) return 'Due Yesterday';
      if (rowTags?.includes('IsOverdue')) return 'Overdue';

      return '';
    }

    default:
      return is(Number, value) ? value.toFixed(2) : value;
  }
};
