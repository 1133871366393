/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { toPercentageString } from '@seeeverything/ui.util/src/value/value.ts';
import { useMemo } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';

export const AutomatedActionsHeaderDetails: React.FC = () => {
  const module = useFormsSelector((state) => state.tenantState.tenant.module);

  const automatedActions = useFormsSelector(
    (state) => state.automatedActionConfiguration.automatedActions,
  );

  const isLoading = useFormsSelector(
    (state) =>
      !state.automatedActionConfiguration.selectedTemplate ||
      state.automatedActionConfiguration.isLoading,
  );

  const totalRows = useMemo(() => {
    if (isLoading || !automatedActions) return '-';
    return automatedActions.filter((a) => a.inCurrentDefinition).length;
  }, [isLoading, automatedActions]);

  const smartActions = useMemo(() => {
    if (isLoading || !automatedActions) return '-';
    return automatedActions.filter((a) =>
      Boolean(a.description && a.inCurrentDefinition),
    ).length;
  }, [isLoading, automatedActions]);

  const withoutActions = useMemo(() => {
    if (isLoading || !automatedActions) return '-';
    const count = automatedActions.filter(
      (a) => !a.description && a.inCurrentDefinition,
    ).length;
    const totalActive = automatedActions.filter((a) => a.inCurrentDefinition);
    const percentage = toPercentageString(count / totalActive.length);
    return `${count} (${percentage})`;
  }, [isLoading, automatedActions]);

  return (
    <div css={styles.base}>
      <div css={styles.details}>
        <div css={[styles.detail, styles.borderRight]}>
          <Text
            color={color.format(-0.4)}
            uppercase={true}
            size={13}
            weight={600}
          >
            {'Total Rows'}
          </Text>
          <Text color={color.format(-0.9)}>{totalRows}</Text>
        </div>
        <div css={[styles.detail, styles.borderRight]}>
          <Text
            color={color.format(-0.4)}
            uppercase={true}
            size={13}
            weight={600}
          >
            {module === 'coaching' ? 'Playbook Actions' : 'Smart Actions'}
          </Text>
          <Text color={color.format(-0.9)}>{smartActions}</Text>
        </div>
        <div css={styles.detail}>
          <Text
            color={color.format(-0.4)}
            uppercase={true}
            size={13}
            weight={600}
          >
            {'Without Actions'}
          </Text>
          <Text color={color.format(-0.9)}>{withoutActions}</Text>
        </div>
      </div>
      <Text style={styles.info} color={color.format(-0.5)} size={14}>
        {'Use {abc} for any placeholder text, e.g. {document required}.'}
      </Text>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  }),
  details: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
  }),
  detail: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  }),
  borderRight: css({
    borderRight: `solid 1px ${color.format(-0.3)}`,
    paddingRight: 15,
  }),
  info: css({
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
  }),
};
