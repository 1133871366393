/** @jsxImportSource @emotion/react */
import { FormStatusBarType } from '@seeeverything/ui.forms/src/components/FormStatusBar/FormStatusBar.tsx';
import {
  confirmRevertSignoff,
  serverCreate,
  userRevertedSignoff,
} from '@seeeverything/ui.forms/src/redux/form-instance/instance/actions.ts';
import { Signature } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { formActionSlice } from '@seeeverything/ui.forms/src/redux/formAction/index.ts';
import {
  IActionPlanLine,
  ISignoffLine,
} from '@seeeverything/ui.forms/src/types/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { scrollSlice } from '@seeeverything/ui.util/src/redux/scroll/index.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { next } from '../../redux/query/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { SheetError } from '../SheetError/SheetError.tsx';
import { SheetForm } from './SheetForm.tsx';
import { useSheetFormIndexItems } from './useSheetFormIndexItems.ts';

export interface ISheetFormContainerProps {
  instanceId: string;
  header?: React.ReactElement;
}

export const SheetFormContainer: React.FC<ISheetFormContainerProps> = ({
  instanceId,
  header,
}) => {
  const [showErrorReloadingSpinner, setShowErrorReloadingSpinner] =
    useState(false);

  const dispatch = useShellDispatch();

  const module = useShellSelector((state) => state.tenantState.tenant.module);

  const loadError = useShellSelector(
    (state) => state.formInstance.errors.load?.[instanceId],
  );

  const createError = useShellSelector(
    (state) => state.formInstance.errors.create?.[instanceId],
  );

  const showGeneralError = useShellSelector(
    (state) => state.formInstance.errors.showGeneralError,
  );

  useEffect(() => {
    if (showErrorReloadingSpinner && !loadError)
      setShowErrorReloadingSpinner(false);
  }, [instanceId, loadError, showErrorReloadingSpinner]);

  const query = useShellSelector((state) => state.query.query);

  const reloadForm = useCallback(() => {
    dispatch(next('INPUT', query, undefined, true));
    setShowErrorReloadingSpinner(true);
  }, [dispatch, query]);

  const retryCreateInstance = useCallback(() => {
    if (!createError) return;
    dispatch(
      serverCreate({
        instanceId,
        templateId: createError.templateId,
        templateDefinitionId: createError.templateDefinitionId,
        templateName: createError.templateName,
        categoryName: createError.categoryName,
      }),
    );
    setShowErrorReloadingSpinner(true);
  }, [createError, dispatch, instanceId]);

  const instanceSubject = useShellSelector(
    (state) => state.formInstance.instances[instanceId]?.subject,
  );

  const canUpdateInstance = useShellSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return true; // Form designer

    const isStatusEditable = ['InProgress', 'Created'].includes(
      instance?.status?.status ?? '',
    );

    return isStatusEditable && instance.permissions?.edit;
  });

  const showValidation = useShellSelector((state) =>
    state.formInstance.errors.validation.ids.some((id) => id === instanceId),
  );

  const issues = useShellSelector((state) => state.formIssue.issues);
  const insights = useShellSelector((state) => state.formInsight.insights);

  const handleCreateAction = useCallback(() => {
    dispatch(
      formActionSlice.newDraft({
        actionId: uuid.generate(),
        assignedTo:
          instanceSubject?.kind === 'Person'
            ? { id: instanceSubject.id, name: instanceSubject.name }
            : undefined,
        instanceInsights: (insights ?? []).map((i) => ({
          id: i.id,
          label: i.label,
          formSectionName: i.formSectionName,
          classifications: (i.classifications ?? []).map((c) => c.value),
          type: i.type,
        })),
        instanceIssues: (issues ?? []).map((i) => ({
          id: i.id,
          label: i.label,
          formSectionName: i.formSectionName,
          issueCoachingRequired: i.issueCoachingRequired,
        })),
        formInstanceSubject:
          instanceSubject?.kind === 'Person'
            ? {
                id: instanceSubject.id,
                label: instanceSubject.name,
                type: instanceSubject.kind === 'Person' ? 'PERSON' : 'TEAM',
              }
            : undefined,
        openedFromFormInstanceId: instanceId,
      }),
    );
  }, [dispatch, insights, instanceId, instanceSubject, issues]);

  const signoffRevertReasonRequired = useShellSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return;

    const signoffLine = instance.lines?.signoff as ISignoffLine;
    if (!signoffLine) return;

    return signoffLine.reviewerRevertReasonRequired;
  });

  const instanceName = useShellSelector(
    (state) => state.formInstance.instances[instanceId]?.name,
  );

  const formLines = useShellSelector(
    (state) => state.formInstance.instances[instanceId]?.lines,
  );

  const user = useShellSelector(
    (state) => state.tenantState.tenant.authenticatedUser,
  );

  const instancePermissions = useShellSelector(
    (state) => state.formInstance.instances[instanceId]?.permissions,
  );

  const instanceReviewer = useShellSelector(
    (state) => state.formInstance.instances[instanceId]?.reviewer,
  );

  const instanceSignOffs = useShellSelector(
    (state) => state.formInstance.instances[instanceId]?.signOffs,
  );

  const handleOnRevertSignoff = useCallback(() => {
    if (!instancePermissions) return;
    if (!instanceSignOffs?.length) return;

    const signOffIdToRevert = instanceSignOffs.find((signOff) => {
      // Prefer to revert subject signoff before reviewer if possible.
      if (instancePermissions.subjectRevertSignOff)
        return signOff.id === instanceSubject?.id;
      if (instancePermissions.assignedToRevertSignOff)
        return signOff.id === instanceReviewer?.id;
    })?.id;

    if (!signOffIdToRevert) return;

    const isReviewer =
      instancePermissions.assignedToRevertSignOff &&
      signOffIdToRevert === instanceReviewer?.id;

    const signature: Signature = {
      ...user,
      type: isReviewer ? 'REVIEWER' : 'SUBJECT',
    };

    if (signoffRevertReasonRequired === true && isReviewer) {
      dispatch(confirmRevertSignoff(instanceId, signOffIdToRevert, signature));
    } else {
      dispatch(userRevertedSignoff(instanceId, signOffIdToRevert, signature));
    }
  }, [
    dispatch,
    instanceId,
    instancePermissions,
    instanceReviewer?.id,
    instanceSignOffs,
    instanceSubject?.id,
    signoffRevertReasonRequired,
    user,
  ]);

  const scrollToDataId = useCallback(
    (dataId: string) => {
      dispatch(
        scrollSlice.scrollToDataId({
          scrollContainerId: 'scrollPanel',
          dataId,
          offsetPx: 40,
          smooth: true,
        }),
      );
    },
    [dispatch],
  );

  const indexItems = useSheetFormIndexItems({ instanceId, lines: formLines });

  const firstErrorDataId = useMemo<string>(() => {
    if (!indexItems?.length) return;

    return indexItems.find((item) => item.error)?.id;
  }, [indexItems]);

  useEffect(() => {
    if (!showValidation) return;
    if (!firstErrorDataId) return;

    scrollToDataId(firstErrorDataId);
  }, [firstErrorDataId, showValidation, scrollToDataId]);

  const scrollToSignoff = useCallback(() => {
    scrollToDataId('signoff');
  }, [scrollToDataId]);

  const isLoading = !formLines || showErrorReloadingSpinner;

  const canWithdrawForm = useShellSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return [];

    return Boolean(instance.permissions.cancel);
  });

  const moreItems = useMemo(
    () =>
      [
        {
          id: 'FORM_AUDIT_LOG',
          dataTest: 'toolbar-pdfExportMenu-auditLogSelection',
          icon: Icons.infoCard,
          content: 'Audit Log',
        },
        {
          id: 'FORM_EXPORT_INSTANCE_TO_PDF',
          dataTest: 'shell-sheetForm-exportToPdfSelection',
          icon: Icons.pictureAsPdf,
          content: 'Export to PDF',
        },
        canWithdrawForm && {
          id: 'SHEET_OPTION_CANCEL_FORM',
          dataTest: 'shell-sheetForm-withdrawSelection',
          icon: Icons.clear,
          content: 'Withdraw',
        },
      ].filter(Boolean),
    [canWithdrawForm],
  );

  const score = useShellSelector((state) => state.formScore.score);

  const locale = useShellSelector((state) => state.tenantState.tenant.locale);

  const showAddActionButton = useShellSelector((state) => {
    if (state.tenantState.tenant.module === 'coaching') return false;

    const instance = state.formInstance.instances[instanceId];
    if (!instance) return false;

    const hasSubject = Boolean(instance.subject?.id);
    if (!hasSubject) return false;

    const canEditInstance =
      instance.status.status !== 'Completed' &&
      instance.permissions.edit === true;
    if (!canEditInstance) return false;

    if (!instance.lines) return false;

    const hasActionPlan = Boolean(
      (instance.lines.actionPlan as IActionPlanLine)?.isEnabled,
    );
    if (hasActionPlan) return true;

    const hasDetachableActionPlan = Object.values(instance.lines).some(
      (line) =>
        line.id !== 'actionPlan' &&
        line.type === 'actionPlan' &&
        line.isDetachable &&
        line.isEnabled,
    );
    return hasDetachableActionPlan;
  });

  const instanceStatus = useShellSelector(
    (state) => state.formInstance.instances[instanceId]?.status?.status,
  );

  const title = useMemo(
    () => [locale.label.form, instanceName].filter(Boolean).join(' - '),
    [instanceName, locale.label.form],
  );

  const statusBarType = useMemo((): FormStatusBarType => {
    if (showGeneralError) return 'GENERAL_ERROR';

    if (showValidation) return 'VALIDATION_ERROR';

    if (
      instanceStatus === 'Appealed' &&
      !instancePermissions?.assignedToRevertSignOff
    )
      return 'UNDER_APPEAL';

    if (
      instanceStatus === 'Appealed' &&
      instancePermissions?.assignedToRevertSignOff
    )
      return 'UNDER_APPEAL_REOPEN';

    if (instanceStatus === 'Pending') {
      const subjectSignedOff = instanceSignOffs?.some(
        (signOff) => signOff.id === instanceSubject?.id,
      );
      const reviewerSignedOff = instanceSignOffs?.some(
        (signOff) => signOff.id === instanceReviewer?.id,
      );
      if (!subjectSignedOff && instanceSubject?.id === user.id)
        return 'AWAITING_REVIEW';

      if (!reviewerSignedOff && instanceReviewer?.id === user.id)
        return 'AWAITING_REVIEW';

      if (!subjectSignedOff && instanceSubject?.id !== user.id)
        return 'AWAITING_REVIEW_SUBJECT';

      if (!reviewerSignedOff && instanceReviewer?.id !== user.id)
        return 'AWAITING_REVIEW_REVIEWER';
    }

    if (
      instancePermissions?.assignedToRevertSignOff ||
      instancePermissions?.subjectRevertSignOff
    )
      return 'FORM_SIGNED_OFF';
  }, [
    instancePermissions?.assignedToRevertSignOff,
    instancePermissions?.subjectRevertSignOff,
    instanceReviewer,
    instanceSignOffs,
    instanceStatus,
    instanceSubject,
    showGeneralError,
    showValidation,
    user.id,
  ]);

  const userScrollToSignoff = useMemo(() => {
    if (
      instanceStatus === 'Pending' &&
      (instancePermissions?.subjectSignOff ||
        instancePermissions?.assignedToSignOff)
    )
      return scrollToSignoff;
  }, [
    instancePermissions?.assignedToSignOff,
    instancePermissions?.subjectSignOff,
    instanceStatus,
    scrollToSignoff,
  ]);

  const isError = Boolean(loadError || createError);
  return isError ? (
    <SheetError
      header={header}
      icon={'refresh'}
      isSpinning={showErrorReloadingSpinner}
      message={
        createError
          ? locale.error.formCreateRetry
          : locale.error.formLoadRefresh
      }
      onClick={createError ? retryCreateInstance : reloadForm}
    />
  ) : (
    <SheetForm
      showAddActionButton={showAddActionButton}
      canUpdateInstance={canUpdateInstance}
      indexItems={indexItems}
      instanceId={instanceId}
      instanceStatus={instanceStatus}
      instanceSubject={instanceSubject}
      instanceReviewer={instanceReviewer}
      isSpinning={isLoading}
      lines={formLines}
      locale={locale}
      moreItems={moreItems}
      onAddActionClicked={handleCreateAction}
      onAwaitingReviewClick={userScrollToSignoff}
      onRevertSignOff={handleOnRevertSignoff}
      score={score}
      scoreIsVisible={module === 'compliance'}
      statusBarType={statusBarType}
      title={title}
    />
  );
};
