import { str } from '@seeeverything/ui.util/src/str/index.ts';
import moment from 'moment';

export const addDisplayValueToFilter = (value: string) => {
  if (!value) return { value: '', displayValue: '(Blank)' };
  if (value === 'InProgress') return { value, displayValue: 'In Progress' };

  return { value, displayValue: str.titleCase(value) };
};

export type FormatDateStringOptions = {
  format?: string;
  defaultValue?: string;
};
export function formatDateString(
  date: string,
  options: FormatDateStringOptions = {},
) {
  const { format = 'D MMM YY, h:mm a', defaultValue } = options;
  return date ? moment(date).format(format) : defaultValue;
}
