/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormIssueCause } from '@se/data/forms/types.ts';
import { IActionBarButton } from '@seeeverything/ui.primitives/src/components/ModalDialog/index.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { DialogOuter } from '@seeeverything/ui.shell/src/components/DialogOuter/index.ts';
import { hideModalDialog } from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { useShellDispatch } from '@seeeverything/ui.shell/src/redux/store.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback, useMemo, useState } from 'react';
import { issueSlice } from '../../redux/issue/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { IssueSecondaryCausesDialog } from './IssueSecondaryCausesDialog.tsx';

export interface IIssueSecondaryCausesDialogContainerProps {
  issueId: string;
  instanceId: string;
}

export const IssueSecondaryCausesDialogContainer: React.FC<
  IIssueSecondaryCausesDialogContainerProps
> = ({ issueId, instanceId }) => {
  const dispatch = useFormsDispatch();
  const shellDispatch = useShellDispatch();
  const [validationCauseIds, setValidationCauseIds] = useState<string[]>([]);

  const instanceCanEdit = useFormsSelector((state) =>
    Boolean(state.formInstance.instances?.[instanceId]?.permissions.edit),
  );

  const formLabel = useFormsSelector(
    (state) => state.tenantState.tenant?.locale.label.form,
  );

  const isSaving = useFormsSelector(
    (state) =>
      state.formIssue.issues?.find((issue) => issue.id === issueId)?.issueCauses
        .isSpinning,
  );

  const issue = useFormsSelector((state) =>
    state.formIssue.issues?.find((formIssue) => formIssue.id === issueId),
  );

  const tenantCauses = useFormsSelector(
    (state) => state.formIssue?.issueCauses ?? [],
  );

  const initialCausesState = useMemo<FormIssueCause[]>(() => {
    if (!issue) return [];

    const deprecatedCauses = (issue.issueCauses.causes ?? [])
      .filter(
        (issueCause) =>
          tenantCauses.every(
            (tenantCause) => tenantCause.id !== issueCause.issueCauseId,
          ) &&
          !issueCause.isPrimary &&
          issueCause.isSelected,
      )
      .map(({ issueCauseId, label, notes }) => ({
        index: -1,
        issueCauseId,
        label,
        notes,
        isSelected: true,
        isPrimary: false,
      }));

    return [
      ...deprecatedCauses,
      ...tenantCauses.map((tenantCause, index) => {
        const existingCause = issue.issueCauses.causes.find(
          (existing) => existing.issueCauseId === tenantCause.id,
        );

        if (existingCause?.isPrimary) return;
        if (!tenantCause.isActive && !existingCause) return;

        return {
          index,
          issueCauseId: tenantCause.id,
          label: tenantCause.label,
          notes: existingCause?.notes,
          isSelected: Boolean(existingCause?.isSelected),
          isPrimary: false,
        };
      }),
    ].filter(Boolean);
  }, [issue, tenantCauses]);

  const [updatedCauses, setUpdatedCauses] = useState(initialCausesState);

  const toggleCauseSelected = useCallback((causeId: string) => {
    setValidationCauseIds((existing) =>
      existing.filter((existingId) => existingId !== causeId),
    );
    setUpdatedCauses((existing) =>
      existing.map((existingCause) =>
        existingCause.issueCauseId === causeId
          ? {
              ...existingCause,
              isSelected: !existingCause.isSelected,
              notes: undefined,
            }
          : existingCause,
      ),
    );
  }, []);

  const updateCauseDetails = useCallback(
    (causeId: string, updatedDetails: string) => {
      setValidationCauseIds((existing) =>
        existing.filter((existingId) => existingId !== causeId),
      );
      setUpdatedCauses((existing) =>
        existing.map((existingCause) =>
          existingCause.issueCauseId === causeId
            ? { ...existingCause, notes: updatedDetails }
            : existingCause,
        ),
      );
    },
    [],
  );

  const validateCauseDetails = useCallback(() => {
    const incompleteCauseIds = updatedCauses
      .filter((cause) => cause.isSelected && !cause.isPrimary && !cause.notes)
      .map((cause) => cause.issueCauseId);

    setValidationCauseIds(incompleteCauseIds);

    const isValid = !incompleteCauseIds.length;
    return isValid;
  }, [updatedCauses]);

  const save = useCallback(() => {
    const isValid = validateCauseDetails();
    if (!isValid) return undefined;

    dispatch(
      issueSlice.saveSecondaryCauses({
        issueId,
        instanceId,
        updatedCauses,
      }),
    );
  }, [validateCauseDetails, dispatch, issueId, instanceId, updatedCauses]);

  const cancel = useCallback(() => {
    shellDispatch(hideModalDialog());
  }, [shellDispatch]);

  const actionButtons: IActionBarButton[] = [
    { id: 'Cancel', label: 'Cancel', isEnabled: true, onAction: cancel },
    { id: 'OK', label: 'OK', onAction: save, isEnabled: instanceCanEdit },
  ];

  const elSavingOverlay = isSaving && (
    <div css={styles.savingOverlay}>
      <Spinner />
    </div>
  );

  return (
    <div css={styles.savingContainer}>
      {elSavingOverlay}
      <DialogOuter actions={actionButtons}>
        <IssueSecondaryCausesDialog
          formLabel={formLabel}
          issueCauses={updatedCauses}
          onCauseToggled={toggleCauseSelected}
          onCauseDetailsChanged={updateCauseDetails}
          validationCauseIds={validationCauseIds}
          canEdit={instanceCanEdit}
        />
      </DialogOuter>
    </div>
  );
};

const styles = {
  savingOverlay: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: color.format(0.8),
    zIndex: 1,
  }),
  savingContainer: css({
    position: 'relative',
    flex: '1 1 auto',
  }),
};
